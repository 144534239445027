import React from "react"
import { motion } from "framer-motion"
import BlockContent from '@sanity/block-content-to-react'
import { Link } from "gatsby"
import { InView } from 'react-intersection-observer';

import { revealInOut } from "../helpers/transitionHelper"
import urlFor from '../helpers/imageBuilder'
import compact from 'lodash/compact'

const HeroText = ({module}) => (
  <motion.div variants={revealInOut} className="heroText">
    <h2>{module.title}</h2>
    <div>
      <BlockContent blocks={module._rawText}/>
    </div>
  </motion.div>
)

const HeroImage = ({module}) => {
  const hasLink = !!module.imageLink

  if (hasLink) {
    const type = module?.imageLink?._type
    const prefix = type === 'research' ? 'projects' : type === 'blog' ? type : null
    const url = compact([prefix, module?.imageLink?._rawSlug?.current])

    return (
      <motion.div variants={revealInOut} className="heroImage">
        <Link to={`/${url.join('/')}`}>
          <img src={urlFor(module._rawImage).width(1500).height(600).url()} alt=""/>
        </Link>
      </motion.div>
    )
  }

  return (
  <motion.div variants={revealInOut} className="heroImage">
    <img src={urlFor(module._rawImage).width(1500).height(600).url()} alt=""/>
  </motion.div>
)}

const LongText = ({module}) => (
  <motion.div variants={revealInOut} className="LongText">
    <h2>{module.heading}</h2>
    <div>
      <BlockContent blocks={module._rawBody}/>
      <Link to={module.link} className="button">{module.linkText}</Link>
    </div>  
  </motion.div>
)

const PeopleBlock = ({module}) => (
  <InView threshold={0.2}>
    {({ref, inView}) => (
      <motion.div 
        ref={ref} 
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={{enter: { transition: { staggerChildren: .1 }}}}
        className="PeopleBlock"
      >
        <motion.h2 >{module.heading}</motion.h2>
        <div className="peopleGallery">
          {module.people.map((person) => (
            <Link className="person-link" to={`/researcher/${person.slug.current}`}>
              <motion.div variants={revealInOut} className="person" key={person._key}>
                <img src={urlFor(person._rawPortrait).width(700).height(800).url()} alt={person.name}/>
                <h3 className="line-hover">{person.name}</h3>
                {person.shortCv && <p className="person_description">{person.shortCv}</p>}
                <p className="person-read-bio">read bio +</p>
              </motion.div>
            </Link>
          ))}
        </div>  
      </motion.div>
    )}
  </InView>
)

const ContentBuilder = ({modules}) => {
    function builder(module) {
        switch(module._type) {
            case "heroText":   return <HeroText module={module}/>;
            case "heroImage":  return <HeroImage module={module}/>;
            case "longText":   return <LongText module={module}/>;
            case "peopleBlock":   return <PeopleBlock module={module}/>;
            default:           return <h1>No match</h1>
        }
    }
    return (
        <>
            {modules.map(module => builder(module))}
        </>
    )
}

export default ContentBuilder;
