import React from "react"
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import ContentBuilder from '../components/ContentBuilder'
import urlFor from '../helpers/imageBuilder'
import { InView } from 'react-intersection-observer';


const IndexPage = ({data, childAnimationDelay}) => {
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <motion.div variants={fadeSlow}>
        <motion.div 
            className="content-builder" 
            variants={{
              enter: { transition: { 
                staggerChildren: .1,
                delayChildren: childAnimationDelay
              }}
            }}>
              <ContentBuilder modules={data.sanityPage.contentBuilder}/>

                <InView threshold={0.2}>
                  {({ref, inView}) => (
                    <motion.div 
                      ref={ref} 
                      initial="initial"
                      animate={inView ? "enter" : "initial"}
                      variants={{enter: { transition: { staggerChildren: .1 }}}}
                      className="LongText"
                    >
                      <motion.h2 variants={revealInOut}>Partners</motion.h2>

                      <motion.div 
                        className="partner-list" 
                        variants={{
                          enter: { transition: { 
                            staggerChildren: .05
                          }}
                        }}>

                        {data.allSanityPartners.nodes.map((partner) => (
                          <motion.div className="partner-block" variants={revealInOut}>
                            <a href={partner.url} target="_blank" rel="noopener">
                            <img src={urlFor(partner.logo._rawAsset).width(400).url()} alt=""/>
                            </a>
                          </motion.div>
                        ))}
                    
                      </motion.div>
                    </motion.div>
                  )}
                </InView>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    sanityPage(title: {eq: "Home"}) {
      id
      title
      contentBuilder {
        ... on SanityHeroImage {
          _key
          _type
          _rawImage
          imageLink: link {
            ... on SanityBlog {
              id
              _rawSlug
              _type
            }
            ... on SanityResearch {
              id
              _rawSlug
              _type
            }
            ... on SanityPage {
              id
              _rawSlug
              _type
            }
          }
        }
        ... on SanityHeroText {
          _key
          _type
          title
          _rawText
        }
        ... on SanityLongText {
          _key
          _type
          _rawBody
          heading
          link
          linkText
          showLink
        }
        ... on SanityPeopleBlock {
          _key
          _type
          heading
          people {
            _id
            name
            shortCv
            links {
              link
              title
            }
            slug {
              current
            }
            _rawPortrait
          }
        }
      }
    }
    allSanityPartners {
      nodes {
        title
        slug {
          current
        }
        logo {
            _rawAsset
        }
        url
      }
    }
  }
`